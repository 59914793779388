// import logo from './logo.svg';
// import './App.css';
import { useEffect, useState } from 'react';
import { Container, Grid, SimpleGrid, Skeleton, useMantineTheme, Title, Badge, Space, Paper, TextInput, TextInputProps, ActionIcon, LoadingOverlay, rem } from '@mantine/core';
import { IconSearch, IconArrowRight, IconArrowLeft } from '@tabler/icons-react';

// import { YoutubeVideoStats } from /components/YoutubeVideoStats';
import { YoutubeVideoStatsTable } from '../components/YoutubeVideoStatsTable';

function extractVideoId(input) {
  let videoId = input;

  // Check if input is a YouTube URL
  if (input.includes("www.youtube.com")) {
    const url = new URL(input);
    videoId = url.searchParams.get('v');
  }

  return videoId;
}

export default function VideoDetails() {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const theme = useMantineTheme();

  const [videoId, setVideoId] = useState();
  const [videoIdInput, setVideoIdInput] = useState(params.get('id'));

  function searchVideo() {

    const extractedVideoId = extractVideoId(videoIdInput)

    if (params.get('id') !== extractedVideoId) {
      // params.set('id', extractedVideoId)
      // window.location.search = params;

      const url = new URL(window.location);
      url.searchParams.set('id', extractedVideoId);
      window.history.pushState(null, '', url.toString());
    }

    setVideoId(extractedVideoId);
  }

  useEffect(() => {
    const id = params.get('id');

    if (!id) return;

    const filteredId = extractVideoId(id);

    if (id !== filteredId) {
      const url = new URL(window.location);
      url.searchParams.set('id', filteredId);
      window.history.pushState(null, '', url.toString());
    }
    
    if (id) {
      searchVideo()
    }
  }, [])

  return (
    <Container size="md" my="lg">


      <TextInput
        icon={<IconSearch size="1.1rem" stroke={1.5} />}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            searchVideo()
          }
        }}
        radius="xl"
        size="md"
        value={videoIdInput}
        onChange={(event) => setVideoIdInput(event.currentTarget.value)}
        rightSection={
          <ActionIcon size={32} radius="xl" color={theme.primaryColor} variant="filled" onClick={searchVideo} >
            {theme.dir === 'ltr' ? (
              <IconArrowRight size="1.1rem" stroke={1.5} />
            ) : (
              <IconArrowLeft size="1.1rem" stroke={1.5} />
            )}
          </ActionIcon>
        }
        placeholder="Youtube Video ID or Youtube Video URL"
        rightSectionWidth={42}
      // {...props}
      />

      <Space h="md" />


      {videoId && <YoutubeVideoStatsTable videoId={videoId} />}

    </Container>
  );
}


function TagBadge({ children }) {
  return <Badge size="md" style={{ marginRight: 4, marginBottom: 4 }}>{children}</Badge>
}

function YoutubeVideoFrame({ videoId, height, width }) {
  return <iframe height={height} style={{ borderRadius: 8 }} width="100%" src={`https://www.youtube-nocookie.com/embed/${videoId}?controls=0`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
}