import React, { useState, useEffect } from 'react';
import { Table, Accordion, Title, Image, LoadingOverlay } from '@mantine/core';
import { getVideoDetails, getVideoMetadata } from '../api/youtube.js';
import moment from 'moment';

// The conversion function
function convertDataToObjectArray(data) {
    return [
        { key: "Title", value: data.video_title },
        { key: "Video Url", value: <a href={`https://www.youtube.com/watch?v=${data.video_id}`} target='_blank'>https://www.youtube.com/watch?v={data.video_id}</a> },
        { key: "Video Length", value: `${data.length_seconds} seconds` },
        { key: "Category", value: data.category },
        { key: "Views", value: parseInt(data.view_count).toLocaleString() },
        { key: "Is Unplugged Corpus", value: data.is_unplugged_corpus ? "Yes" : "No" },
        { key: "Is Live Content", value: data.is_live_content ? "Yes" : "No" },
        { key: "Has Ads", value: data.has_ads ? "Yes" : "No" },
        { key: "Streaming Platforms", value: data.has_streaming_platforms ? "Yes" : "No" },
        { key: "Publish Date", value: moment(data.publish_date).format('YYYY-MM-DD') },
        { key: "Upload Date", value: moment(data.upload_date).format('YYYY-MM-DD') },
        { key: "Song", value: data.song || "" },
        { key: "Artist", value: data.artist || "" },
        { key: "Album", value: data.album || "" },
        { key: "Licenses", value: data.licenses || "" },
        { key: "Channel", value: data.author },
        { key: "Channel ID", value: data.channel_id },
        { key: "Profile Url", value: <a href={data.owner_profile_url} target='_blank'>{data.owner_profile_url}</a> },
        { key: "Verified Channel", value: data.badge_name?.includes("VERIFIED") ? "Yes" : "No" },
        { key: "Verified", value: data.badge_name?.includes("VERIFIED") ? "Yes" : "No" },
        { key: "Verified Badge", value: data.badge_name },
        { key: "Contact Emails", value: data.contact_emails.join(", ") || "" },
        { key: "Added Date (System)", value: moment(data.date_added).toLocaleString() || "" },
        { key: "Modified Date (System)", value: moment(data.date_modified).toLocaleString() || "" }
    ];
}
function Link(url) {
    return <a href={url} target='_blank'>{url}</a>
}

function ImageWithLink(url) {
    return url && <div style={{ alignContent: 'center', alignItems: 'center' }}><div style={{ display: 'inline-block' }}><Image onClick={() => window.open(url, "_blank", "noreferrer")} style={{ cursor: 'pointer' }} maw={50} mx="auto" radius="md" src={url} alt="Random image" /></div></div>
}

function convertMetadataToObjectArray(data) {
    // console.log(data);
    // return []
    if (!data.data) return [];

    const trackData = data.data[0];
    return [
        { key: "Name", value: trackData?.name },
        { key: "Duration (Seconds)", value: (parseInt(trackData?.duration_ms || 0) / 1000).toFixed(2) },
        { key: "Track Number", value: trackData?.track_number },
        { key: "ISRC", value: trackData?.isrc },
        { key: "Artists", value: trackData?.artists?.map(artist => artist.name).join(", ") },
        { key: "Album", value: trackData?.album?.name },
        { key: "Album Release Date", value: trackData?.album?.release_date },
        { key: "Album Cover", value: ImageWithLink(trackData?.album?.covers?.large) },
        { key: "UPC", value: trackData?.album?.upc },
        { key: "Label", value: trackData?.label },
        { key: "Spotify Link", value: Link(trackData?.external_metadata?.spotify?.[0].link) },
        { key: "Spotify Preview Link", value: Link(trackData?.external_metadata?.spotify?.[0].preview) },
        { key: "Spotify Album Cover", value: ImageWithLink(trackData?.external_metadata?.spotify?.[0]?.album?.cover) },
        { key: "YouTube Music Link", value: Link(trackData.external_metadata.youtube[0].link) },
        { key: "YouTube Music Artist Link", value: Link(trackData?.external_metadata.youtube[0]?.album?.link) },
        { key: "YouTube Music Album Cover", value: ImageWithLink(trackData?.external_metadata?.youtube?.[0]?.album?.cover) },

        { key: "Type", value: trackData.type },
    ];
}

function convertViewsDataToTableArray(data) {
    return data?.map((element) => (
        { key: moment(element.view_date).toLocaleString(), value: parseInt(element.view_count).toLocaleString() }
    ));
}

export function YoutubeVideoStatsTable({ videoId }) {

    const [videoDetails, setVideoDetails] = useState();
    const [videoViews, setVideoViews] = useState();
    const [videoDescription, setVideoDescription] = useState();
    const [metadata, setMetadata] = useState();
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState(false);

    useEffect(() => {
        setError(false);
        LoadVideoDetails();
    }, [videoId]);

    async function LoadVideoDetails() {

        try {
            setLoading(true);
            getVideoDetails(videoId).then((video) => {
                setVideoDetails(convertDataToObjectArray(video));
                setVideoViews(convertViewsDataToTableArray(video?.video_views_data))
                setVideoDescription(video?.short_description);
                setLoading(false);
            })

            getVideoMetadata(videoId).then((metadata) => {
                setMetadata(convertMetadataToObjectArray(metadata));
            });
            
        } catch (error) {
            console.log(error)
            setError(true);
        } finally {
            
        }

    }
    // useEffect(() => {
    //     LoadVideoDetails()
    // }, []);

    const videoDetailsRow = videoDetails?.map((element) => (
        <tr key={element.key}>
            <td>{element.key}</td>
            <td>{element.value}</td>
        </tr>
    ));

    const videoViewsRow = videoViews?.map((element) => (
        <tr key={element.key}>
            <td>{element.key}</td>
            <td>{element.value}</td>
        </tr>
    ));

    const metadataRow = metadata?.map((element) => (
        <tr key={element.key}>
            <td>{element.key}</td>
            <td>{element.value}</td>
        </tr>
    ));

    return (
        <>
            <LoadingOverlay visible={loading} overlayBlur={2} />
            {!error ? videoDetails && <Accordion variant="separated" radius="lg" defaultValue="details">

                <Accordion.Item value="details">
                    <Accordion.Control>Video Details</Accordion.Control>
                    <Accordion.Panel>
                        <Table highlightOnHover>
                            <tbody>{videoDetailsRow}</tbody>
                        </Table>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="description">
                    <Accordion.Control>Video Description</Accordion.Control>
                    <Accordion.Panel><p dangerouslySetInnerHTML={{ __html: videoDescription.replace(/\n/g, "<br />") }} /></Accordion.Panel>
                    {/* <Accordion.Panel>{videoDescription}</Accordion.Panel> */}
                </Accordion.Item>

                <Accordion.Item value="views">
                    <Accordion.Control>Video Views</Accordion.Control>
                    <Accordion.Panel>
                        <Table highlightOnHover>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Views</th>
                                </tr>
                            </thead>
                            <tbody>{videoViewsRow}</tbody>
                        </Table>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="metadata">
                    <Accordion.Control>Metadata / Copyright</Accordion.Control>
                    <Accordion.Panel>
                        <Table highlightOnHover>
                            {metadata ? <tbody>{metadataRow}</tbody> : <tbody><tr><td>Loading...</td></tr></tbody>}

                        </Table>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion> : <Title order={2}>Video not found</Title>}
        </>

    );
}