// import logo from './logo.svg';
// import './App.css';
import { Container, Grid, SimpleGrid, Skeleton, useMantineTheme, Title, Badge, Space, Paper, AppShell, rem } from '@mantine/core';
import { YoutubeVideoStats } from './components/YoutubeVideoStats';
import { YoutubeVideoStatsTable } from './components/YoutubeVideoStatsTable';

import Navbar from './components/NavBar';
import VideoDetails from './pages/VideoDetails';

const PRIMARY_COL_HEIGHT = rem(300);
const videoId = 'mFZf4KcsV7I';

export default function App() {
  const theme = useMantineTheme();
  const SECONDARY_COL_HEIGHT = `calc(${PRIMARY_COL_HEIGHT} / 2 - ${theme.spacing.md} / 2)`;

  const keywords = [
    "Corridos",
    "Corrido Tumbado",
    "Video Oficial",
    "Nata Montana",
    "Mas Altas Que Bajadas",
    "Natanael Cano",
    "Corrido",
    "Los CT",
    "Corridos Tumbados",
    "Luis R Conriquez",
    "Pancakes",
    "Peso Pluma",
    "AMG",
    "Pacas De Billetes",
    "Tito Torbellino Jr",
    "Mi Bello Angel",
    "Viejo Lobo",
    "Ghini",
    "Eres",
    "Junior H",
    "En Corto",
    "Dan Sanchez",
    "Corrido Tumbados 2023"
  ]

  return (
    <AppShell
      padding="md"
      navbar={<Navbar/>}
      // header={<Header height={60} p="xs">{/* Header content */}</Header>}
      // styles={(theme) => ({
      //   main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] },
      // })}
    >
      <VideoDetails/>
      {/* Your application here */}
    </AppShell>
  );
}


function TagBadge({ children }) {
  return <Badge size="md" style={{ marginRight: 4, marginBottom: 4 }}>{children}</Badge>
}

function YoutubeVideoFrame({ videoId, height, width }) {
  return <iframe height={height} style={{ borderRadius: 8 }} width="100%" src={`https://www.youtube-nocookie.com/embed/${videoId}?controls=0`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
}