import axios from 'axios';

const SERVER_URL = 'https://api.gogle.io';

export function getVideoDetails(videoId) {
  return axios.get(`${SERVER_URL}/video?id=${videoId}`)
    .then(response => response.data)
}

export function getVideoMetadata(videoId) {
  return axios.get(`${SERVER_URL}/metadata?id=${videoId}`)
    .then(response => response.data)
}
